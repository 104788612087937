<template>
  <div class="view-container">
    <div class="view">
      <div
          :style="{'background-color': backgroundColor}"
          class="view-heading p-0"
      >
        <span class="disabled-nav">{{ $t('system.user_information') }}</span>
      </div>
      <div class="view-body">
        <el-form
            class="profile-form"
            label-position="top"
            label-width="220px"
        >
          <div class="row">
            <div class="col-9">
              <div class="row">
                <el-form-item
                    :label="$t('users.username')"
                    class="col-6"
                >
                  <el-input
                      :value="username"
                      disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('system.email')"
                    class="col-6"
                >
                  <el-input
                      :value="email"
                      disabled
                  ></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item
                    :label="$t('users.password')"
                    class="col-6"
                >
                  <el-input
                      :class="backend_errors.password ? 'validate-error' : ''"
                      name="password"
                      type="password"
                      v-model="password"
                  ></el-input>
                  <small
                      class="help-block"
                      v-if="backend_errors.password && !checkinputRequired('password')"
                  >
                    <i class="fa fa-times-circle-o"></i>
                    {{ $t(backend_errors.password[0]) }}
                  </small>
                </el-form-item>
                <el-form-item
                    :label="$t('users.password_confirmation')"
                    class="col-6"
                >
                  <el-input
                      :class="backend_errors.password_confirmation ? 'validate-error' : ''"
                      name="password_confirmation"
                      type="password"
                      v-model="password_confirmation"
                  ></el-input>
                  <small
                      class="help-block"
                      v-if="backend_errors.password_confirmation && !checkinputRequired('password_confirmation')"
                  >
                    <i class="fa fa-times-circle-o"></i>
                    {{ $t(backend_errors.password_confirmation[0]) }}
                  </small>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item class="col-12">
                  <div class="d-flex justify-content-end">
                    <el-button
                        :disabled="!isPassword"
                        :loading="loading"
                        @click="save"
                        type="primary"
                    >{{ $t('system.save') }}
                    </el-button>
                    <el-button @click.prevent.stop="backTo">
                      {{ $t('system.cancel') }}
                    </el-button>
                  </div>
                </el-form-item>
              </div>
            </div>
            <div
                class="col-3"
                style="border-left: 1px solid #bbbbbb"
            >
              <el-form-item :label="$t('users.avatar')">
                <el-upload
                    :action="action"
                    :before-upload="beforeAvatarUpload"
                    :headers="headerInfo"
                    :on-error="handleError"
                    :on-progress="onProgress"
                    :on-success="handleAvatarSuccess"
                    :show-file-list="false"
                    accept="image/*"
                    class="avatar-uploader"
                    drag
                    style="width: 100%; height: 100%;"
                    v-loading="avatarLoading"
                >
                  <img
                      :src="avatar"
                      class="avatar w-100"
                      v-if="avatar"
                  >
                  <i
                      class="el-icon-plus avatar-uploader-icon"
                      v-else
                  ></i>
                </el-upload>
              </el-form-item>
              <el-form-item class="d-flex justify-content-end">
                <el-tooltip
                    :content="$t('system.remove_avatar')"
                    effect="dark"
                    placement="bottom"
                    v-if="avatar"
                >
                  <!--<font-awesome-icon style="color: #F56C6C;" icon="trash" @click="onRemove" size="4x"/>-->
                  <el-button
                      @click.prevent.stop="onRemove"
                      type="danger"
                  >
                    {{ $t('system.delete') }}
                  </el-button>
                </el-tooltip>
              </el-form-item>
              <!--              <el-form-item :label="$t('user.system_color')">-->
              <!--                <ThemePicker/>-->
              <!--              </el-form-item>-->
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import {mapActions, mapGetters, mapState} from 'vuex';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

export default {
  name: 'UserProfile',
  components: {
    // ThemePicker: () => import('../../components/ThemePicker/index.vue')
  },
  data() {
    return {
      form: {},
      backend_errors: {},
      inputRequiredEmpty: [],
      title: 'Create',
      //initialize: 'profile/' + this.userId,
      redirect: '/profile',
      store: 'profile',
      method: 'put',
      password: '',
      password_confirmation: '',
      ibiznes_alias: '',
      loading: false,
      avatarLoading: false,
      // action: `${process.env.BASE_API}/uploadAvatar&clientId=${this.$store.getters['auth/clientId']}`,
      action: `${process.env.VUE_APP_ROOT_API}/uploadAvatar?clientId=${this.$store.getters['auth/clientId']}`,
      headerInfo: {
        'Access-Control-Allow-Origin': `${process.env.VUE_APP_ROOT_API}/uploadAvatar&clientId=${this.$store.getters['auth/clientId']}`,
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    };
  },
  computed: {
    ...mapGetters([
      'auth/user',
    ]),
    ...mapState('auth', ['avatar']),
    isPassword() {
      return this.password !== '' && this.password_confirmation !== '';
    },
    username: function () {
      return this.$store.getters['auth/username'];
    },
    email: function () {
      return this.$store.getters['auth/user'].email;
    },
    userId: function () {
      return this.$store.getters['auth/user'].id;
    },
    isActive: function () {
      return this.$store.getters.active;
    },
    backgroundColor() {
      return this.$store.state.app.color;
    },
  },
  beforeMount() {
    this.fetchData();
  },
  watch: {
    '$route': 'fetchData',
  },
  methods: {
    ...mapActions('auth', ['fetchAvatar']),
    fetchData() {
      var vm = this;
      axios.get('profile/' + this.userId)
          .then(function (response) {
            Vue.set(vm.$data, 'ibiznes_alias', response.data.ibiznes_alias);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    backTo: function () {
      window.history.back();
    },
    checkinputRequired(name) {
      if (_.includes(this.inputRequiredEmpty, name)) return true;
    },
    getinputRequired() {
      var vm = this;
      var inputs = document.querySelectorAll('.is-required .el-input__inner');
      vm.inputRequiredEmpty = [];
      _.forEach(inputs, function (item) {
        if (item.value.length === 0) vm.inputRequiredEmpty.push(item.name);
      });
    },
    save() {
      let vm = this;
      vm.getinputRequired();
      // if (this.password !== this.password_confirmation) {
      //   vm.$message({
      //     dangerouslyUseHTMLString: true,
      //     message: vm.$t('system.password_not_match'),
      //     type: 'warning'
      //   });
      //   return false;
      // }
      var data = {
        password: vm.password,
        password_confirmation: vm.password_confirmation,
        ibiznes_alias: vm.ibiznes_alias,
      };
      vm.loading = true;
      axios[this.method](this.store, data)
          .then(function (response) {
            vm.loading = false;
            if (response.data.saved) {
              vm.password_confirmation = vm.password = '';
              notification.notify(
                  vm.$t('notify.success'),
                  response.data.message,
                  'success');
            }
            // vm.$router.push(vm.redirect)
          })
          .catch(function (error) {
            vm.loading = false;
            let dataErrors = error.response.data.errors;
            let errors = Global.getErrors(vm, dataErrors, 1);
            // if (dataErrors.password[0] === 'validation.min.string') {
            //   vm.$message({
            //     dangerouslyUseHTMLString: true,
            //     message: vm.$t('system.password_min_length'),
            //     type: 'error'
            //   });
            // }
            vm.$store.commit('SET_STICKY_ARRAY', {
              array: errors,
            });
            Vue.set(vm.$data, 'backend_errors', dataErrors);
          });
    },
    onProgress(event, file, fileList) {
      console.log();
    },
    async handleAvatarSuccess(res, file) {
      await this.fetchAvatar();
      this.avatarLoading = false;
    },
    handleError() {
      this.avatarLoading = false;
      notification.notify(
          this.$t('notify.error'),
          this.$t('system.upload_error'),
          'error');
    },
    async beforeAvatarUpload(file) {
      this.avatarLoading = true;
      const isImg = file.type.startsWith('image/');
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isImg) {
        this.this.avatarLoading = false;
        return this.$message.error('File must be image format!');
      }
      if (!isLt2M) {
        this.this.avatarLoading = false;
        return this.$message.error('Avatar picture size can not exceed 500kB!');
      }
      await this.removeAvatar();
    },
    removeAvatar() {
      return axios.delete(`avatar`)
          .then((response) => {
            // this.$store.commit('auth/SET_USER', response.data);
            // if (!_.isEmpty(response.data)) {
            //   window.localStorage.setItem('user', JSON.stringify(response.data));
            // }
            // notification.notify(
            //   vm.$t('notify.success'),
            //   vm.$t('system.removed'),
            //   'success');
          })
          .catch(() => {
            notification.notify(
                this.$t('notify.error'),
                this.$t('system.not_removed'),
                'error');
            this.avatarLoading = false;
          });
    },
    async onRemove() {
      this.avatarLoading = true;
      await this.removeAvatar();
      await this.fetchAvatar();
      this.avatarLoading = false;
      // this._self['auth/user'].avatar = URL.createObjectURL(file.raw);
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.avatar-uploader {
  display: flex;

  .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.profile-form {
  .el-form-item__label {
    margin: 0;
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-form-item {
  margin-bottom: 15px;
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.content-setting {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, .1);
  background: white;

  .nav {
    display: block;

    .el-menu-item {
      height: auto;
      line-height: 100%;
      border: none !important;
      padding: 0;

      a {
        height: 60px;
        line-height: 60px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        color: #a7a2a2;

        &:hover {
          color: #fff;
        }
      }

      .router-link-active {
        color: #fff;
      }
    }

    .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: none !important;
    }
  }
}

.disabled-nav {
  color: #fff;
  font-weight: 600;
  border-bottom: 2px solid #fff;
  line-height: 60px;
  padding: 0 20px;
  display: inline-block;
  font-size: 14px;
}

.project-form {
  overflow: hidden;
  padding: 25px 25px 0;
  padding-bottom: 0;
}

.avatar {
  width: auto;
  height: auto;
  display: block;
}

.el-upload-dragger {
  min-width: 350px;
  min-height: 350px;
  width: auto !important;
  height: auto !important;
  display: block;
}

.color-picker {
  top: 15px
}

.select-full-width {
  .el-select {
    width: 100%;
  }
}
</style>
